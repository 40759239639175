
import {projectFirestore} from '../../../../../firebase/config'
import { ref } from 'vue'
import {user} from '../../../../../Methods/Utils'

const branches = ref([])
const who_not_uploaded_image = ref([])

const get_events_calendar_from_db = async() => {
    try{
        const docs = await projectFirestore.collection('Calendar').doc('events').collection('EventList').orderBy('date_object','desc').get()
        return docs.docs.map(doc => doc.data())
        
    }catch(err){
        throw new Error(err)
    }
}

async function handle_who_not_uploaded_image(event){
    who_not_uploaded_image.value = []
    const set = new Set()
    for(const {branche_num} of event.media){
        set.add(branche_num)
    }

    for(const {name,branche_num,marked} of event.authorized){
        if(!set.has(branche_num) && marked){
            who_not_uploaded_image.value.push({
                name,
                branche_num
            })
        }
    }

    console.log('check_2:',who_not_uploaded_image.value);
}

const reload_branches_list = async() => {
    try{
        const docs = await projectFirestore.collection('Shivuk').doc('Shivuk')
        .collection('Branches').get()
        branches.value = docs.docs.map(doc => doc.data())
        console.log('check:',branches.value);
    }catch(err){
        throw new Error(err.message)
    }
}

const update_calendar_log = async(event) => {
    try{
        const doc = projectFirestore.collection('CalendarLogs').doc()
        await doc.set({
            event:event.title,
            date:new Date(),
            user:user.value,
            media:event.media
        },{merge:true})
    }catch(err){
        console.log(err);
    }
}





export{
    get_events_calendar_from_db,
    branches,
    reload_branches_list,
    handle_who_not_uploaded_image,
    who_not_uploaded_image,
    update_calendar_log,
}