<template>
  <div class="calendar-manage">
    <div v-loading="is_pending" class="calendar-manage-wrapper">
        <div v-once class="calendar-manage-header">
            <h2>ניהול יומן פעילויות</h2>
        </div>
        <div class="calendar-manage-tools">
            <el-input style="width:30%;" v-model="search" placeholder="חיפוש..." />
        </div>
        <div class="calendar-manage-content">
            <table>
                <tr v-once>
                    <th style="cursor:pointer;" @click="handleSortTable('תאריך', !selectedSorted.order)">תאריך</th>
                    <th style="cursor:pointer;" @click="handleSortTable('שם', !selectedSorted.order)">שם</th>
                    <th style="cursor:pointer;" @click="handleSortTable('אישורים', !selectedSorted.order)">אישורים</th>
                    <th>משתתפים</th>
                    <th>מדיה</th>
                    <th>כמות מדיה</th>
                    <th>מס ימים לתזכורת</th>
                    <th>פעולות</th>
                    <th></th>
                </tr>
                <template v-for="event in sorted_events" :key="event.id">
                    <tr>
                        <td>{{israeli_format_Date_string(event.start)}}</td>
                        <td>{{event.title}}</td>
                        <td @click="selected_event_for_approved_list=event" style="cursor:pointer; user-select:none;">{{event.approved.length}}</td>
                        <td>{{event.authorized.filter(({marked}) => marked).length}}</td>
                        <td>
                            <i @click="selected_event_images=event.media" v-if="event.media.length > 0" class="material-icons image-icon">image</i>
                            <i @click="selected_event_images_for_grid=event" v-if="event.media.length > 0" class="material-icons image-icon">grid_view</i>
                        </td>
                        <td @click="handle_update_media_amount(event)" style="cursor:pointer; user-select:none;">
                            {{event.images_amount || 'לא צויין'}}
                        </td>
                        <td @click="handle_update_days_alarm(event)" style="cursor:pointer; user-select:none;">{{event.days_alarm}}</td>
                        <td>
                            <i @click="selected_event_to_autorized = event" class="material-icons image-icon">group</i>
                            <i @click="selected_event_to_edit = event" class="material-icons edit-icon">edit</i>
                            <i @click="handle_delete_event(event)" class="material-icons image-icon delete-icon">delete</i>
                        </td>
                        <td @click="handle_open_accordion(event.id); handle_who_not_uploaded_image(event)">
                            <span :class="{rotate:selected_event_id==event.id}" class="material-icons arrow_icon">expand_more</span>
                        </td>
                    </tr>
                    <tr v-if="selected_event_id==event.id">
                        <td colspan="9">
                            <table>
                                <tr>
                                    <th style="background:#333;">תמונה</th>
                                    <th style="background:#333;">תאריך</th>
                                    <th style="background:#333;">שם משתמש</th>
                                    <th style="background:#333;">סניף</th>
                                    <th style="background:#333;">מס סניף</th>
                                    <th style="background:#333;"></th>
                                </tr>
                                <tr v-if="event.media.length == 0">
                                   <td colspan="5">
                                        <h1>לא קיימות נתונים להצגה</h1>
                                    </td> 
                                </tr>
                                <template v-for="{image,branche,branche_num,date,user_name} in event.media" :key="image">
                                    <tr>
                                        <td><img style="width:50px;" :src="image" alt="תמונה לא נטענה"></td>
                                        <td>{{display_date_from_iso(date)}}</td>
                                        <td>{{user_name || 'לא צויין'}}</td>
                                        <td>{{branche || 'לא צויין'}}</td>
                                        <td>{{branche_num || 'לא צויין'}}</td>
                                        <td>
                                            <i @click="handle_delete_media(event,image,user_name,branche)" class="material-icons delete-icon">delete</i>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </td>
                    </tr>
                    <tr v-if="selected_event_id==event.id">
                        <td colspan="9" style="background:var(--success); color:#fff;" v-if="who_not_uploaded_image.length==0">נראה כי כל הסניפים העלו תמונה</td>
                        <td v-else colspan="9">
                            <table>
                                <tr>
                                    <td colspan="2">
                                        <h1 style="color:var(--danger);">סניפים שלא העלו תמונה עדיין</h1>
                                    </td> 
                                </tr>
                                <tr>
                                   <th style="background:var(--danger)">מס סניף</th> 
                                   <th style="background:var(--danger)">שם סניף</th> 
                                </tr>
                                <template v-for="{branche_num,name} in who_not_uploaded_image" :key="branche_num">
                                    <tr>
                                        <td>{{branche_num || 'לא צויין'}}</td>
                                        <td>{{name || 'לא צויין'}}</td>
                                    </tr>                     
                                </template>
                            </table>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="calendar-manage-pagination">
            <el-pagination 
                v-model:currentPage="pagination.current_page"
                background 
                :page-size="pagination.number_per_page"
                layout="prev,pager,next"
                :total="filter_by_search.length" 
            />
        </div>
    </div>
    <ShowCalendarEventImages 
        v-if="selected_event_images" 
        @close="selected_event_images=null"
        :images="selected_event_images"
    />

    <ShowCalendarEventImagesGrid 
        v-if="selected_event_images_for_grid"
        @close="selected_event_images_for_grid=null"
        :event="selected_event_images_for_grid"
    />

    <ShowEventDialog 
        v-if="selected_event_to_edit" 
        :is_edit="true"
        @close="selected_event_to_edit=null" 
        :event_data="selected_event_to_edit"
        @update_event="handle_update_event"
        @delete_event="handle_delete_edit_event"
        @submit_edit="handle_submit_edit"
        @submit_approved="handle_submit_approved"
        @submit_upload_image="handle_submit_upload_image"
    />

    <ApprovedsList
        v-if="selected_event_for_approved_list"
        @close="selected_event_for_approved_list=null"
        :event="selected_event_for_approved_list"
     />

    <AutorizedLists 
        v-if="selected_event_to_autorized" 
        @close="selected_event_to_autorized=null"
        :event="selected_event_to_autorized"
        @update_authorized="handle_update_authorized"
    />

  </div>
</template>

<script>
import {slide_pop_error,slide_pop_successs,alert_confirm} from '../../../../../Methods/Msgs'
import {israeli_format_Date_string,display_date_from_iso} from '../../../../../Methods/Dates'
import upload_image_storage from '../../../../../Methods/use_general_storage'
import {get_events_calendar_from_db} from './script'
import {delete_event_from_db,update_event_data_in_db} from '../../script'
import {handle_who_not_uploaded_image,who_not_uploaded_image} from './script'
import {update_calendar_log} from './script'
import { computed, defineAsyncComponent, ref } from 'vue'
import Swal from 'sweetalert2'

export default {
    components:{
        ShowCalendarEventImages:defineAsyncComponent(() => import('../ShowCalendarEventImages.vue')),
        ShowCalendarEventImagesGrid:defineAsyncComponent(() => import('../ShowCalendarEventImagesGrid.vue')),
        ShowEventDialog:defineAsyncComponent(() => import('../ShowEventDialogg.vue')),
        ApprovedsList:defineAsyncComponent(() => import('./components/ApprovedsList.vue')),
        AutorizedLists:defineAsyncComponent(() => import('./components/AutorizedLists.vue')),
    },
    setup(){

        const {delete_image_by_url} = upload_image_storage()

        const selected_event_images_for_grid = ref(null)
        const selected_event_id = ref(null)
        const is_pending = ref(false)

        const selected_event_to_autorized = ref(null)
        const selected_event_for_approved_list = ref(null)
        const selected_event_to_edit = ref(null)

        const selected_event_images = ref(null)
        const search = ref('')
        const events = ref([])

        const handle_open_accordion = (event_id) => {
            if(!selected_event_id.value) {
                selected_event_id.value = event_id
            }
            else if(selected_event_id.value == event_id){
                selected_event_id.value = null
            }
            else{
                selected_event_id.value = event_id
            }
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const handle_delete_media = async(event,_image,user_name,branche) => {
            try{
                console.log(event,_image,user_name,branche);
                alert_confirm(`למחוק את התמונה של המשתמש ${user_name} מסניף ${branche}?`)
                .then(async res => {
                    if(res.isConfirmed){
                        is_pending.value = true
                        const index = event.media.findIndex(({image}) => image == _image)
                        if(index!=-1){
                            event.media.splice(index,1)
                            await update_event_data_in_db({
                                media:event.media,
                                id:event.id
                            })
                            await delete_image_by_url(_image)
                            await update_calendar_log(event)
                            is_pending.value = false
                        }else{
                            throw new Error('לא ניתן למחוק את התמונה!')
                        }
                    }
                })
            }catch(err){
                is_pending.value = false
                slide_pop_error(err.message)
            }
        }
        const sorted_events = ref(computed(() => {
            if (selectedSorted.value.name == "")return pagination_slice.value;
            if (selectedSorted.value.name == "תאריך") {
                return pagination_slice.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a.date_object.seconds*1000) >  new Date(b.date_object.seconds*1000)
                        ? 1
                        : new Date(b.date_object.seconds*1000)  > new Date(a.date_object.seconds*1000)
                        ? -1
                        : 0;
                    }
                    return new Date(a.date_object.seconds*1000) > new Date(b.date_object.seconds*1000) 
                    ? -1
                    : new Date(b.date_object.seconds*1000)  > new Date(a.date_object.seconds*1000)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם") {
                return pagination_slice.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.title >  b.title
                        ? 1
                        : b.title  > a.title
                        ? -1
                        : 0;
                    }
                    return a.title > b.title 
                    ? -1
                    : b.title  > a.title
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "אישורים") {
                return pagination_slice.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.approved.length >  b.approved.length
                        ? 1
                        : b.approved.length  > a.approved.length
                        ? -1
                        : 0;
                    }
                    return a.approved.length > b.approved.length 
                    ? -1
                    : b.approved.length  > a.approved.length
                    ? 1
                    : 0;
                });
            }
        }))

        const pagination = ref({
            current_page:1,
            number_per_page:20,
        })

        const selectedSorted = ref({
            name: "",
            order: false
        });

        const pagination_slice = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))
        
        const filter_by_search = ref(computed(() => {
            if(!search.value) return events.value
            return events.value.filter(event => {
                if(israeli_format_Date_string(event.start).includes(search.value)) return event
                if(event.title.includes(search.value)) return event            
            })
        }))

        const handle_delete_event = (event) => {
            try{
                alert_confirm(`למחוק את האירוע "${event.title}" מיומן הפעילויות?`)
                .then(async res => {
                    if(res.isConfirmed){
                        is_pending.value = true
                        await delete_event_from_db(event.id)
                        events.value = events.value.filter(({id}) => id != event.id)
                        is_pending.value = false
                    }
                })
            }catch(err){
                is_pending.value = false
                slide_pop_error(err)
            }
        }

        /* For Edit */
        const handle_update_event = ({id,backgroundColor}) => {
            const index = events.value.findIndex(e => e.id == id)
            if(index != -1){
                events.value[index].backgroundColor = backgroundColor
            }
        }

        const handle_delete_edit_event = (event_uid) => {
            const index = events.value.findIndex(e => e.id == event_uid)
            if(index!=-1){
                events.value.splice(index,1)
                selected_event_to_edit.value = null
            }
        }

        const handle_submit_edit = ({id,description}) => {
            const index = events.value.findIndex(e => e.id == id)
            if(index != -1){
                events.value[index].description = description
            }
        }

        const handle_submit_approved = ({id,approved}) => {
            const index = events.value.findIndex(e => e.id == id)
            if(index != -1){
                events.value[index].approved = approved
            }
        }

        const handle_submit_upload_image = ({id,media}) => {
            const index = events.value.findIndex(e => e.id == id)
            if(index != -1){
                events.value.media = media
            }
        }

       

        const handle_update_authorized = async(event) => {
            try{
                await update_event_data_in_db({
                    id:event.id,
                    authorized:event.authorized
                })
                const index = events.value.findIndex(({id}) => id==event.id)
                if(index != -1){
                    events.value[index].authorized = event.authorized
                }
                slide_pop_successs(`רשימת המשתתפים לאירוע "${selected_event_to_autorized.value.title}" עודכנה בהצלחה!`)
                selected_event_to_autorized.value = null
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const handle_update_days_alarm = async(event) => {
            const { value: number } = await Swal.fire({
                title: '',
                inputValue:event.days_alarm,
                input: 'number',
                inputLabel: 'מספר ימי תזכורת',
                inputPlaceholder:event.days_alarm,
                customClass: {
                    input: 'swal2-input'
                },
                inputAttributes: {
                    min: 1,
                    max: 10,
                },
                width:'200px',
                confirmButtonText :'עדכון'
            })

            if(number){
              try{
                await update_event_data_in_db({
                    id:event.id,
                    days_alarm:number
                })
                
                const index = events.value.findIndex(({id}) => id==event.id)
                if(index != -1){
                    events.value[index].days_alarm = number
                }
                slide_pop_successs(`מס ימים לאירוע ${event.title} עודכן בהצלחה`)

              }catch(err){
                slide_pop_error(err.message)
              } 
            }
        }

        const handle_update_media_amount = async(event) => {
            const { value: number } = await Swal.fire({
                title: '',
                inputValue:event.images_amount || 1,
                input: 'number',
                inputLabel: 'מספר כמות מדיה',
                inputPlaceholder:event.images_amount || 'לא צויין',
                customClass: {
                    input: 'swal2-input'
                },
                inputAttributes: {
                    min: 1,
                    max: 30,
                },
                width:'200px',
                confirmButtonText :'עדכון'
            })

            if(number){
              try{
                await update_event_data_in_db({
                    id:event.id,
                    images_amount:number
                })
                
                const index = events.value.findIndex(({id}) => id==event.id)
                if(index != -1){
                    events.value[index].images_amount = number
                }
                slide_pop_successs(`מס כמות מדיה לאירוע ${event.title} עודכן בהצלחה`)

              }catch(err){
                slide_pop_error(err.message)
              } 
            }
        }

        const init = async() => {
            try{
                events.value = await get_events_calendar_from_db()
                console.log(events.value);
                // if(branches.value.length == 0){
                //     reload_branches_list()
                // }
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        init()

      

        return{
            init,
            israeli_format_Date_string,
            handleSortTable,
            handle_delete_event,
            search,
            events,
            filter_by_search,
            pagination,
            pagination_slice,
            selectedSorted,
            sorted_events,
            selected_event_images,
            is_pending,
            selected_event_to_edit,
            handle_update_event,
            handle_delete_edit_event,
            handle_submit_edit,
            handle_submit_approved,
            handle_submit_upload_image,
            selected_event_for_approved_list,
            selected_event_to_autorized,
            handle_update_authorized,
            handle_update_days_alarm,
            display_date_from_iso,
            selected_event_id,
            handle_open_accordion,
            handle_who_not_uploaded_image,
            who_not_uploaded_image,
            handle_delete_media,
            selected_event_images_for_grid,
            handle_update_media_amount,
        }
    }
}
</script>

<style scoped>
    ::v-deep(.swal2-input) {
        width: 100% !important;
    }
    .calendar-manage{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .calendar-manage-wrapper{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    .calendar-manage-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .calendar-manage-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .calendar-manage-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow: auto;
        padding: 0 5px;
    }
    .calendar-manage-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-icon,.edit-icon,.delete-icon{
        user-select: none;
        color: var(--blue);
        font-size: 30px;
        cursor: pointer;
    }
    .edit-icon{
        color: var(--warning);
        margin: 0 5px;
    }
    .delete-icon{
        color: var(--danger);
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        font-size: 20px;
    }
    
    

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--purple);
        color: #fff;
    }
    .arrow_icon{
        user-select: none;
        cursor: pointer;
        transition: all 0.4s ease;
    }
    .arrow_icon.rotate{
        transform: rotate(180deg);
    }
</style>