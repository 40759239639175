import { projectFirestore,FireStore } from "../../../firebase/config";
import {role,user} from '../../../Methods/Utils'

const set_new_event_to_db = async(event) => {
    try{
        const doc = projectFirestore.collection('Calendar').doc('events').collection('EventList').doc()
        await doc.set({
            ...event,
            id:doc.id
        })

        return doc.id
    }catch(err){
        throw new Error(err.message)
    }
}

const reload_events_from_db = async() => {
    try{
        const docs = await projectFirestore.collection('Calendar').doc('events').collection('EventList').get()
        const events = docs.docs.map(doc => doc.data())
        return events
    }catch(err){
        throw new Error(err)
    }
}
const reload_events_from_db_if_authorized = async(_branche_num) => {
    try{
        const docs = await projectFirestore.collection('Calendar').doc('events').collection('EventList').get()
        const events = docs.docs.map(doc => doc.data()).filter(e => {
            return (e.authorized.some( auth => auth.branche_num == _branche_num && auth.marked))
        })
        console.log(events);
        return events
    }catch(err){
        throw new Error(err)
    }
}

const update_event_data_in_db = async(event_data) => {
    try{
        console.log('event_data:',event_data);
        await projectFirestore.collection('Calendar').doc('events').collection('EventList').doc(event_data.id)
        .set({
            ...event_data
        },{merge:true})
    }catch(err){
        throw new Error(err)
    }
}

const update_approved = async(event_uid,approved_obj) => {
    try{
        console.log('check approved_obj:',approved_obj);
        await projectFirestore.collection('Calendar').doc('events').collection('EventList').doc(event_uid)
        .update({
            approved: FireStore.FieldValue.arrayUnion(approved_obj)
        })
    }catch(err){
        throw new Error(err)
    }
}

const update_media = async(event_uid,media_object) => {
    console.log('check update media');
    try{
        await projectFirestore.collection('Calendar').doc('events').collection('EventList').doc(event_uid)
        .update({
            media: FireStore.FieldValue.arrayUnion(media_object)
        })
    }catch(err){
        throw new Error(err)
    }
}


const delete_event_from_db = async(event_uid) => {
    try{
        await projectFirestore.collection('Calendar').doc('events')
        .collection('EventList').doc(event_uid).delete()
    }catch(err){
        throw new Error(err)
    }
}

const get_future_events = async() => {
    try{
        const currentDate = FireStore.Timestamp.now();
        const docs = await projectFirestore.collection('Calendar').doc('events')
        .collection('EventList').where('date_object','>',currentDate).orderBy('date_object','asc').get()
        return docs.docs.map(doc => doc.data())
    }catch(err){
        throw new Error(err)
    }
}

export{
    set_new_event_to_db,
    reload_events_from_db,
    update_event_data_in_db,
    delete_event_from_db,
    reload_events_from_db_if_authorized,
    get_future_events,
    update_media,
    update_approved
}